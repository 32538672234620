<template>
  <div sign-up-progress :class="[landingMode, {'mobile': isMobile}]" v-if="currentItem">
    <div ref="items" v-if="structure">
      <template v-if="!isComplete">
        <RouteBack :structure="structure" v-if="showRouteBack" @back="back" :hold="step === 0"/>
        <StepTitle :structure="structure" />
        <ValidationObserver class="field-holder" ref="validator" v-slot="{ invalid }">
          <component :key="currentItem.key" :is="currentItem.template" :model-info="modelProxy" :item-info="currentItem" :structure="structure" @update="updateValue" @next="confirm" />
          <div :class="{'btn-holder': true, pt: buttonSize !== 'small' }">
            <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
            <ProgressButton enter :button-name="(lastStep || npOnly) ? 'submit' : 'next'" :progress="progress" :disabled="invalid || structure.error || disabled" :name="buttonTag" :class="buttonClass" :size="buttonSize" @click="confirm" />
          </div>
        </ValidationObserver>
        <component v-if="currentItem.footer" :is="currentItem.footer" :structure="structure" />
      </template>
      <component v-else :is="currentItem.template" :structure="structure" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { state } from '@shared/utils/storeUtils.mjs';
import { focusSelector, sleep } from '@shared/utils/commonUtils.mjs';
import { isUrl } from '@shared/utils/urlUtils.mjs';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { isLocal, isTest, isExcludeGgpass, isExcludeTnc, completeRedirectUrl } from '@/utils/baseUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import { getObjectIndexByProperty } from '@/utils/valueUtil';
import RouteBack from '@/views/components/common/RouteBackButton.vue';
import StepTitle from '@/views/components/pages/sign-up/StepTitle.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ComponentProvider from '@/views/components/common/ComponentProvider.vue';
import LandingHeader from '@/views/components/pages/sign-up/LandingHeader.vue';
import ToggleButton from '@shared/components/common/ToggleButton.vue';
import CaptchaSwitch from '@/views/components/extra/CaptchaSwitch.vue';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { routeNames } from '@/constants/base/signup/signUpSiteMap';
import FromLanding from '@/views/components/pages/sign-up/asset/FromLanding.vue';
import { trackGtmEvent } from '@/plugins/tracking';
import { requestBrandWebCloseModal, sendSignupReadyToBrandWeb } from '@/utils/webSiteUtil';

export default {
  name: 'SignUpProgress',
  components: { FromLanding, CaptchaSwitch, ToggleButton, LandingHeader, ComponentProvider, ProgressButton, StepTitle, RouteBack },
  data() {
    return {
      disabled: true,
      structure: null,
      captcha: null,
      modelProxy: null,
      errorMsg: null,
      progress: false,
      isFromMobileApp: getCookie('isFromMobileApp') || this.$route.query.isFromMobileApp,
      isFromClientApp: getCookie('isFromClientApp') || this.$route.query.isFromClientApp,
      redirectUri: getCookie('redirectUri') || this.$route.query.redirectUri,
    };
  },
  watch: {
    async $route() {
      await this.reset();
    },
  },
  computed: {
    env: state('env', 'env'),
    brand: state('env', 'brand'),
    site: state('env', 'site'),
    ggpass: state('env', 'ggpass'),
    npOnly: state('env', 'npOnly'),
    csEmail: state('env', 'csEmail'),
    routeNames: state('env', 'routeNames'),
    landingMode: state('env', 'landingMode'),
    brandSignup: state('env', 'brandSignup'),
    skipSignupPersonalInfo: state('env', 'skipSignupPersonalInfo'),
    config: state('config', 'info'),
    token: state('user', 'token'),
    signedPw: state('user', 'signedPw'),
    signedEmail: state('user', 'signedEmail'),
    signedMobileNumber: state('user', 'signedMobileNumber'),
    isMobile: state('browser', 'mobile'),
    invite: state('query', 'invite'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.npOnly || this.structure.list.length === 1 || this.structure.step >= this.structure.list.length - 2;
    },
    showRouteBack() {
      if (this.npOnly) return false;
      if ((this.brandSignup && this.skipSignupPersonalInfo) || this.site === siteIds.SEVENXL) return this.step !== 0;
      return true;
    },
    currentItem() {
      return this.structure?.list?.[this.step];
    },
    currentRoute() {
      return this.currentItem?.route;
    },
    sendVerificationType() {
      return this.modelProxy.SendVerificationType;
    },
    action() {
      const t = this.currentItem?.action?.[this.site] || this.currentItem?.action?.default || this.currentItem?.action;
      if (!t) return '';
      return t?.[this.sendVerificationType || 'EMAIL'] || t;
    },
    keys() {
      const t = this.currentItem?.keys;
      if (!t) return '';
      return t?.[this.sendVerificationType || 'EMAIL'] || t;
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if (!t) return '';
      return t?.[this.sendVerificationType || 'EMAIL'] || t;
    },
    buttonClass() {
      const t = this.currentItem?.buttonClass;
      if (!t) return '';
      return t?.[this.sendVerificationType || 'EMAIL'] || t;
    },
    api() {
      return this.currentItem.api[this.site] || this.currentItem.api.default || this.currentItem.api;
    },
    service() {
      return this.currentItem.service[this.site] || this.currentItem.service.default || this.currentItem.service;
    },
    countryCode() {
      return this.$route.query.country || state('user', 'countryCode');
    },
    buttonSize() {
      /** @description PFP-151: GGPRO TnC일 경우 버튼 사이즈 작게 조정 */
      return this.site === siteIds.GGPRO && this.step === 1 ? 'small' : 'medium';
    },
    isComplete() {
      return !this.npOnly && this.structure && this.structure.list.length - 1 === this.step;
    },
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },
    isLocal() {
      return isLocal();
    },
    isTest() {
      return isTest();
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return displayBrandNames[key];
    },
  },
  methods: {
    back() {
      if(this.step === 0) this.replaceRouteName('SignUpCountry', null, { ...this.$route.query, country: undefined });
    },
    async confirm() {
      await this.$validate(this.$refs.validator, focusSelector('.error input'));
      if (this.$gtm && this.buttonTag) trackGtmEvent({ category: this.buttonTag, action: 'progress_button_click' });

      this.captcha = {};
      if (this.currentItem.captcha) this.captcha = await this.$sign.setRecaptcha(this.action);
      await this.apiRequest();
    },
    /**
     * apiRequest
     * @returns {Promise<void>}
     */
    async apiRequest() {
      // ggpass signup 과정은 해당 api 하나로만 처리. this.$services.ggpass.signup
      this.progress = true;

      if(!this.npOnly && this.$route.name === routeNames.SignUpPersonalInfo) {
        const model = this.isMBP ? this.structure.model : { ...this.structure.model, ...await this.$sign.setRecaptcha('SignupCheckUserEmail')};
        const c = await this.$services.sign.publicEmailVerification(model, true);
        if(!await this.apiErrorHandler(c)) return;
      }

      const r = await this.getApiResponse();
      r?.AccessToken && this.$services.store.commit('user/setToken', r.AccessToken);
      if (this.npOnly) {
        if (!await this.apiErrorHandler(r)) return;
        if (this.$route.name === routeNames.SignUpTnc) {
          if (r?.IsPasswordSetRequired && r?.OpAccessToken) {
            this.progress = false;
            this.$store.commit('user/setGpToken', r?.OpAccessToken);
            await this.replaceRouteName(routeNames.SignUpHighestPassword, null, this.$route.query);
            return;
          }
        }

        if (!completeRedirectUrl()) {
          if (isClient()) {
            const oneTimeToken = (!isExcludeGgpass(this.site) && this.token) ? await this.$services.sign.getGcLoginToken() : undefined;
            autoLogin(this, { site: this.site, token: oneTimeToken });
          }
          else close(this);
        }
        return;
      }

      /** 입력된 보너스 코드 체크 안하기로 함. 그냥 bypass*/
      // if (await this.checkInvalidBonusCode(r) && await this.apiErrorHandler(r)) {
      if (await this.apiErrorHandler(r)) {
        this.$store.commit('user/setSignedEmail', this.structure.model.Email);
        this.$store.commit('user/setSignedMobileNumber', this.structure.model.MobileNumber);
        this.$store.commit('user/setSignedPw', this.structure.model.Password);
        await this.nextStep(r);
      }
    },
    /**
     * getApiResponse
     * @returns {Promise<*>}
     */
    async getApiResponse() {
      try {
        const r = await this.$services[this.service][this.api]({ SiteId: this.site, ...this.structure.model, ...this.captcha, Invite: this.invite });
        if(r?.token) this.structure.model.Token = r.token;
        if (r?.accessToken) this.structure.model.AccessToken = r.accessToken;
        return r;
      } catch (e) {
        return e;
      }
    },
    async checkInvalidBonusCode(r) {
      if (r.desc === 'invalidBonusCode') {
        if (process.env.VUE_APP_ENV !== 'production') this.$toast('Invalid Bonus Code.', { type: 'fail', translate: false });
        await this.replaceRouteName('SignUp', null, this.$route.query);
        return false;
      }
      return true;
    },
    /**
     * apiErrorHandler
     * @param r
     * @returns {Promise<*|boolean>}
     */
    async apiErrorHandler(r) {
      const npOnlyRules = [
        {
          rule: r => r.key === apiErrorCode.INTERNAL_ERROR,
          action: () => {
            if (process.env.VUE_APP_ENV !== 'production') this.$toast(this.isGGPBE ? 'validation.rules.partnerCode' : 'validation.rules.bonusCode', { type: 'fail' });
          },
        },
        {
          rule: r => r?.key === apiErrorCode.INVALID_REQUEST,
          action: async () => {
            await this.replaceRouteName('AuthError', {ci: getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCESS_DENIED), cci: getObjectIndexByProperty(apiErrorCode, apiErrorCode.INVALID_REFERENCE_KEY)});
          }
        },
        {
          rule: r => r?.key === apiErrorCode.RESTRICTED_EMAIL_ADDRESS,
          action: async () => {
            await this.replaceRouteName('RestrictedEmail', null, null);
          }
        },
        {
          rule: r => r?.key === apiErrorCode.GGPASS_EMAIL_EMPTY,
          action: async () => {
            await this.replaceRouteName('LoginRestriction', null, null);
          }
        },
        {
          rule: r => r?.code === 401,
          action: false,
        },
        {
          rule: () => true,
          action: r => {
            this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail, brand: this.displayBrandName });
            this.structure.error = true;
          }
        },
        {
          rule: r => r && r.error,
          action: r => {
            this.errorMsg = r?.desc;
            this.structure.error = true;
            return false;
          }
        },
      ];
      const rules = [
        {
          rule: r => r?.key === apiErrorCode.INVALID_BONUS_CODE,
          action: async () => {
            if ([siteIds.NATURAL8, siteIds.N8IN, siteIds.SEVENXL].includes(this.site)) {
              if (this.brandSignup && this.skipSignupPersonalInfo) await requestBrandWebCloseModal(this);
              else await this.replaceRouteName('SignUpCountry', null, this.$route.query);
            } else {
              r.key = apiErrorCode.INVALID_BONUS_CODE;
            }
          },
        },
        {
          rule: r => r?.code === 401,
          action: async () => {
            if (this.brandSignup && this.skipSignupPersonalInfo) await requestBrandWebCloseModal(this);
            else await this.replaceRouteName('SignUpCountry', null, this.$route.query);
          }
        },
        {
          rule: r => [apiErrorCode.REQUIRED_TNC_AGREEMENT, apiErrorCode.REQUIRED_VERIFICATION_CODE].includes(r.key),
          action: () => true,
        },
        {
          rule: r => ['limit', apiErrorCode.RESEND_TERM_LIMITED, apiErrorCode.EMAIL_RESEND_TERM_LIMITED, apiErrorCode.PHONE_RESEND_TERM_LIMITED].includes(r.key),
          action: async r => {
            this.structure.limitTime = +r.value;
            return true;
          }
        },
        {
          rule: r => r.key === apiErrorCode.INTERNAL_ERROR,
          action: () => {
            if (process.env.VUE_APP_ENV !== 'production') this.$toast(this.isGGPBE ? 'validation.rules.partnerCode' : 'validation.rules.bonusCode', { type: 'fail' });
          }
        },
        {
          rule: r => [apiErrorCode.RESEND_LIMITED, apiErrorCode.EMAIL_RESEND_LIMITED, apiErrorCode.MOBILE_RESEND_LIMITED, apiErrorCode.TELESIGN_INVALID_PHONE_NUMBER].includes(r.key),
          action: async r => await this.replaceRouteName(routeNames.SignUpPersonalInfo, { errorMsg: this.$t(r.key, { method: this.sendVerificationType === 'EMAIL' ? this.$t('email') : this.$t('sms') }) }, this.$route.query),
        },
        {
          rule: r => r?.key === apiErrorCode.RESTRICTED_EMAIL_ADDRESS,
          action: async () => {
            await this.replaceRouteName('RestrictedEmail', null, null);
          }
        },
        {
          rule: r => r?.key === apiErrorCode.GGPASS_EMAIL_EMPTY,
          action: async () => {
            await this.replaceRouteName('LoginRestriction', null, null);
          }
        },
        {
          rule: () => true,
          action: r => {
            if (r.key === apiErrorCode.USERNAME_ALREADY_EXISTS) r.key = apiErrorCode.USERNAME_ALREADY_EXISTS;
            else if (r.key === apiErrorCode.INVALID_VERIFICATIONCODE) r.key = apiErrorCode.INVALID_VERIFICATION_CODE;
            // else if (r.key === apiErrorCode.UNMATCHED_VERIFICATION_CODE) r.key = apiErrorCode.INVALID_VERIFICATIONCODE; // 다국어 문구 변경으로 대응 처리 완료로 주석
            else if (r.key === apiErrorCode.INVALID_RECAPTCHA) {
              if (r.desc2?.length) {
                if (r.desc2.includes(apiErrorCode.TOO_MUCH_TRAFFIC)) r.key = apiErrorCode.TOO_MUCH_TRAFFIC;
              } else {
                r.key = apiErrorCode.INVALID_RECAPTCHA_TOKEN;
              }
            }
            const method = this.sendVerificationType === 'EMAIL' ? this.$t('emailAddress') : this.$t('mobileNumber');
            this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail, method, brand: this.displayBrandName });
            this.structure.error = true;
          }
        },
        {
          rule: r => r && r.error,
          action: r => {
            this.errorMsg = r?.desc;
            this.structure.error = true;
            return false;
          }
        },
      ];

      /** structure.errorMsg 는 템플릿 내에서 error 를 처리하는 경우 , errorMsg 는 컨테이너에서 error 를 처리하는 경우 */
      if (r?.code && r?.key) {
        const t = (this.npOnly ? npOnlyRules : rules).find(o => o.rule(r));
        if(t) return t?.action(r);
        return false;
      } else if (r?.error) {
        this.errorMsg = r?.desc;
        this.structure.error = true;
        return false;
      }
      return true;
    },
    async nextStep(r) {
      if (this.lastStep) {
        if (this.npOnly) return false;

        let kycUrl = process.env[`VUE_APP_KYC_URL_${this.site}`];
        if (kycUrl && this.isLocal) kycUrl = `${location?.origin}/onboarding`;
        if ((this.isFromMobileApp || this.isFromClientApp) && kycUrl && this.token) {
          const oneTimeToken = !isExcludeGgpass(this.site) ? await this.$services.sign.getGcLoginToken() : undefined;
          await autoLogin(this, { site: this.site, doLogin: false, email: this.signedEmail, password: this.signedPw, mobileNumber: this.signedMobileNumber, token: oneTimeToken });
          if (this.isFromMobileApp) await sleep(100); // mobile client 일 경우 postMessage 처리 시 mobile client 연동 방식에 따라 0.5초 딜레이를 줌

          const isFromApp = this.isFromMobileApp ? 'isFromMobileApp=true' : 'isFromClientApp=true';
          location.href = `${kycUrl}?verificationType=verification&ViewType=${this.isMobile ? 'mobileType' : 'pcType'}&lang=${this.lang}&token=${r.AccessToken}&${isFromApp}`;
          return false;
        }

        if (this.redirectUri && isUrl(this.redirectUri)) {
          completeRedirectUrl();
          return;
        }
      }

      this.progress = false;
      await this.replaceRouteName(this.structure.list[this.step + 1].route, { limitTime: this.structure.limitTime }, this.$route.query);
    },
    /**
     * TODO : [ean] 사용 범위 체크 후 Proxy쪽으로 이관 필요
     * @returns {Promise<void>}
     */
    async updateValue() {
      const excludeTnc = isExcludeTnc(this.site) && this.npOnly;
      this.disabled = excludeTnc ? false : !_.every(this.keys, o => {
        const m = this.structure?.model?.[o];
        return !(m === null || m === undefined || (_.isBoolean(m) && !m) || (_.isString(m) && !m.length));
      });
    },
    /**
     * @returns {Promise<boolean>}
     */
    async createProxyController() {
      if (this.modelProxy) return;

      const self = this;
      this.modelProxy = new Proxy(this.structure.model, {
        async set(target, prop, value) {
          if (!self.structure) return false;
          if (self.structure.model) {
            if (self.structure.model[prop] !== value) self.structure.errorMsg = null;
            self.structure.model[prop] = value;
          }
          self.errorMsg = null;
          self.structure.errorMsg = null;
          self.structure.error = false;
          self.disabled = !_.every(self.keys, o => self.structure.model?.[o]);
          self.progress = false;
          return true;
        }
      });

      if (!this.ggpass && this.structure.model && !this.structure.model.CountryCode) this.structure.model.CountryCode = (await this.$services.base.getUserCountry()).CountryCode;
    },
    /**
     * @returns {Promise<void>}
     */
    async reset() {
      const model = await this.$sign.getModel();
      this.structure = { ...model };
      if (this.$route.name === routeNames.SignUpPersonalInfo) {
        this.structure.model.IsMarketingAgreement = null;
        this.structure.model.IsAgreeTnC = null;
      }

      await this.createProxyController();

      this.structure.error = false;
      this.structure.errorMsg = null;
      this.modelProxy.SendVerificationType = this.config?.loginType !== 'MOBILE' ? 'EMAIL' : 'MOBILE';

      await sleep(30);
      await this.updateValue();
    },
  },
  async mounted() {
    this.$sign.setScope(this);
    await this.$sign.initialize();
    await this.reset();

    if (this.skipSignupPersonalInfo && (!this.signedEmail || !this.signedPw)) {
      await sendSignupReadyToBrandWeb(this);
      this.$block();
    }
  },
  beforeDestroy() {
    this.structure = null;
    this.modelProxy = null;
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[sign-up-progress] {
  &.mobile { .min-h(540); }
  .btn-holder { .rel(); .mb(40);
    .error { .abs(); .lt(50%, -10); .t-xc(); .c(@c-red;)}
    &.pt { .pt(20); .mt(40); }
  }
  @media (@tp-up) {
  }
}
</style>